import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from './modalpdf'; // Asegúrate de importar el modal

export const SeparatorCV = (props) => {
  const { t } = useTranslation();
  const { title, subtitle, url } = props.data || {};
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="separator-cv">
      <div className="container separator-cv-content">
        <div className="separator-cv-text">
          {title ? <h2>{t('SeparatorCV.title')}</h2> : "..."}
          {subtitle ? <p>{t('SeparatorCV.subtitle')}</p> : "..."}
        </div>
        <div className="separator-cv-button">
          {url ? (
            <button onClick={handleOpenModal} className="ghost-button-cv">
              {t('SeparatorCV.buttonText')}
            </button>
          ) : (
            ".."
          )}
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} url={url} />
    </div>
  );
};
