import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom"; // Importa Routes y Route
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { Gallery } from "./components/gallery";
import { Clients } from "./components/clients";
import { SeparatorCV } from "./components/separatorcv";
import { About } from "./components/about"; 
import { Services } from "./components/services"; 
import { Contact } from "./components/contact"; 
import { Catalog } from "./components/catalog";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet-async";
import { HelmetProvider } from "react-helmet-async";
library.add(fas, faTwitter, faFontAwesome);

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const { i18n } = useTranslation();
  const [landingPageData, setLandingPageData] = useState({});
  const [language, setLanguage] = useState(localStorage.getItem('language') || ''); // Obtén el idioma de localStorage

  useEffect(() => {
    // Establecer el idioma según el estado
    if (language) {
      i18n.changeLanguage(language); // Cambia el idioma en i18next
    } else {
      const userLanguage = navigator.language || navigator.userLanguage; 
      const lang = userLanguage.startsWith('es') ? 'es' : 'en'; 
      setLanguage(lang);
      i18n.changeLanguage(lang); // Cambia el idioma en i18next
    }
  }, [i18n, language]);

  useEffect(() => {
    const fetchLocaleData = async () => {
      const response = await import(`./locales/${language}/translation.json`);
      setLandingPageData(response);
    };

    if (language) {
      fetchLocaleData();
    }
  }, [language]);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang); // Guardar el idioma en localStorage
    i18n.changeLanguage(lang); // Cambia el idioma en i18next cuando cambia el estado
  };

  return (
    <HelmetProvider>
      <div>
        <Navigation onLanguageChange={handleLanguageChange} />
        
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <Helmet>
                  <title>{landingPageData.titleHome}</title>
                  <meta name="description" content={landingPageData.homeDescription || "Descripción de la página de inicio"} />
                </Helmet>
                <Header data={landingPageData.Header} />
                <Features data={landingPageData.Features} />
                <div className="separator-banner">
                  <div className="overlay">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-7 content-block">
                          <h2>{landingPageData.BannerText}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Gallery data={landingPageData.Gallery} />
                <div className="separator-banner-new">
                  <h2>{landingPageData.ClientsText}</h2>
                </div>
                <Clients clientsData={landingPageData.Clients} />
                <SeparatorCV data={landingPageData.SeparatorCV} />
              </>
            } 
          />
          <Route 
            path="/about" 
            element={
              <>
                <Helmet>
                  <title>{landingPageData.titleAbout}</title>
                  <meta name="description" content={landingPageData.aboutDescription || "Descripción sobre nosotros"} />
                </Helmet>
                <About data={landingPageData.About} />
              </>
            } 
          />
          <Route 
            path="/services" 
            element={
              <>
                <Helmet>
                  <title>{landingPageData.titleServices}</title>
                  <meta name="description" content={landingPageData.servicesDescription || "Descripción de servicios"} />
                </Helmet>
                <Services slides={landingPageData.carouselServices} />
              </>
            } 
          />
          <Route 
            path="/contact" 
            element={
              <>
                <Helmet>
                  <title>{landingPageData.titleContact}</title>
                  <meta name="description" content={landingPageData.contactDescription || "Descripción de contacto"} />
                </Helmet>
                <Contact data={landingPageData.Contact} />
              </>
            } 
          />
          <Route 
            path="/catalog" 
            element={
              <>
                <Helmet>
                  <title>{landingPageData.titleCatalog}</title>
                  <meta name="description" content={landingPageData.catalogDescription || "Descripción del catálogo"} />
                </Helmet>
                <Catalog data={landingPageData.Catalog} />
              </>
            } 
          />
        </Routes>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; All rights reserved © 2024 Proin & Proyectos y Servicios
              Industriales.
            </p>
          </div>
        </div>
      </div>
      </HelmetProvider>
  );
};

export default App;
