import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const Header = (props) => {
  const slides = [
    {
      title: props.data ? props.data.title : "Loading",
      subtitle: props.data ? props.data.subtitle : "Loading",
      paragraph: props.data ? props.data.paragraph : "Loading",
      backgroundImage: props.data ? props.data.slide1 : "../img/slide01.webp",
    },
    {
      title: props.data ? props.data.title2 : "Loading",
      subtitle: props.data ? props.data.subtitle2 : "Loading",
      paragraph: props.data ? props.data.paragraph2 : "Loading",
      backgroundImage: props.data ? props.data.slide2 : "../img/slide02.webp",
    },
  ];



  return (
    <header id="header">
      <div className="intro">
        <div className="">
          <div id="myCarousel" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
              {slides.map((_, index) => (
                <li
                  key={index}
                  data-target="#myCarousel"
                  data-slide-to={index}
                  className={index === 0 ? "active" : ""}
                ></li>
              ))}
            </ol>
            <div className="carousel-inner">
              {slides.map((slide, index) => (
                <div
                  className={`item ${index === 0 ? "active" : ""}`}
                  key={index}
                  style={{
                    backgroundImage: `url(${slide.backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    width: "100vw",
                    height: "100vh"
                  }}
                >
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 intro-text text-center">
                      <h1>{slide.title}</h1>
                      <span>{slide.subtitle}</span>
                      <p>{slide.paragraph}</p>
                 
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <a className="left carousel-control" href="#myCarousel" role="button" data-slide="prev">
              <div className="carousel-icon">
                <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
              </div>
              <span className="sr-only">Previous</span>
            </a>
            <a className="right carousel-control" href="#myCarousel" role="button" data-slide="next">
              <div className="carousel-icon">
                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
              </div>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};
