import React from 'react';
import ReactDOM from 'react-dom'; 
import App from './App';
import { BrowserRouter } from 'react-router-dom'; // Importa el enrutador
import './i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import 'flag-icons/css/flag-icons.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';


const element = <FontAwesomeIcon icon={faEnvelope} />;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter> {/* Solo aquí se debe usar el Router */}
      {element} {/* Esto renderiza tu icono */}
      <App /> {/* Esto renderiza tu aplicación */}
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
