import React from "react";
import { useTranslation } from 'react-i18next'; // Importar useTranslation
import Slider from "react-slick";

export const Clients = ({ clientsData }) => { // Receive clientsData as a prop
  const { t } = useTranslation(); // Hook para traducción

  // Flecha Siguiente Personalizada
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow next-arrow`} // Añadir clase para el estilo
      style={{ ...style, display: "block",  background: "black" }} // Mantener el estilo predeterminado
      onClick={onClick}
    >
      &rarr; {/* Puedes usar un icono o texto aquí */}
    </div>
  );
}

// Flecha Anterior Personalizada
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow prev-arrow`} // Añadir clase para el estilo
      style={{ ...style, display: "block", background: "black" }} // Mantener el estilo predeterminado
      onClick={onClick}
    >
      &larr; {/* Puedes usar un icono o texto aquí */}
    </div>
  );
}
  // Configuración del carrusel
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // Pantallas de 1024px o menos
        settings: {
          slidesToShow: 4, // Mostrar 4 imágenes
        }
      },
      {
        breakpoint: 768, // Pantallas de 768px o menos
        settings: {
          slidesToShow: 3, // Mostrar 3 imágenes
        }
      },
      {
        breakpoint: 480, // Pantallas de 480px o menos
        settings: {
          slidesToShow: 2, // Mostrar 2 imágenes
        }
      }
    ]
  };

  // Renderizar el componente solo si hay datos
  if (!clientsData || clientsData.length === 0) {
    return null; // No renderiza nada si no hay datos
  }

  return (
    <div id="clients">
      <div className="container">
        <div className="section-title text-center">
          <p>
            {t('ClientsDescription')} {/* Traducción del texto de descripción */}
          </p>
        </div>
        <Slider {...settings}>
          {clientsData.map((client, index) => (
            <div key={index} className="client">
              <div className="client-image">
                <img src={client.img} alt={`Client ${index + 1}`} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
