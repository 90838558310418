import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from './modalpdf'; // Asegúrate de importar el modal
import Title from './title';

export const Catalog = (props) => {
    const { t } = useTranslation();
    const { title, subtitle, url } = props.data || {};
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentUrl, setCurrentUrl] = useState(null);

    const handleOpenModal = (url) => {
        setCurrentUrl(url);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setCurrentUrl(null);
    };

    return (
        <>
            <div className='catalog-container'>
                <Title title={t("navigation.catalog")} />

                {/* Separator Principal */}
                <div className="separator-cv">
                    <div className="container-cv separator-cv-content">
                        <div className="separator-cv-text">
                            <h2>{title ? t('Catalog.title') : "..."}</h2>
                            <p>{subtitle ? t('Catalog.subtitle') : "..."}</p>
                        </div>
                        <div className="separator-cv-button">
                            {url ? (
                                <button onClick={() => handleOpenModal(t('Catalog.url'))} className="ghost-button-cv">
                                    {t('Catalog.buttonText')}
                                </button>
                            ) : (
                                ".."
                            )}
                        </div>
                    </div>
                    <Modal isOpen={isModalOpen} onClose={handleCloseModal} url={currentUrl} />
                </div>

                {/* Separator ZENDATA */}
                <div className="separator-cv separator-zendata">
                    <div className="container-cv separator-cv-content">
                        <div className="separator-cv-text">
                            <h2>{t('Catalog.zendataTitle')}</h2>
                            <p>{t('Catalog.zendataSubtitle')}</p>
                        </div>
                        <div className="separator-cv-button">
                            <button onClick={() => handleOpenModal(t('Catalog.zendataurl'))} className="ghost-button-cv">
                                {t('Catalog.zendataButtonText')}
                            </button>
                        </div>
                    </div>
                    <Modal isOpen={isModalOpen} onClose={handleCloseModal} url={currentUrl} />
                </div>

                {/* Separator PROIN */}
                <div className="separator-cv separator-proin">
                    <div className="container-cv separator-cv-content">
                        <div className="separator-cv-text">
                            <h2>{t('Catalog.proinTitle')}</h2>
                            <p>{t('Catalog.proinSubtitle')}</p>
                        </div>
                        <div className="separator-cv-button">
                            <button onClick={() => handleOpenModal(t('Catalog.proinurl'))} className="ghost-button-cv">
                                {t('Catalog.proinButtonText')}
                            </button>
                        </div>
                    </div>
                    <Modal isOpen={isModalOpen} onClose={handleCloseModal} url={currentUrl} />
                </div>
            </div>
        </>
    );
};
