import React from 'react';

const Title = ({ title }) => {
  return (
    <div className="title-container">
      <div className="overlay-blue"></div>
      <h1 className="title-text">{title}</h1>
    </div>
  );
};

export default Title;
