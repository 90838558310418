import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h3>{props.data?.title}</h3> {/* Título de la sección */}
          <p>{props.data?.description}</p> {/* Descripción de la sección */}
        </div>
        <div className="row">
          {props.data?.items?.map((d, i) => (
            <div key={`${d.title}-${i}`} className="col-md-4 col-sm-12 ">
              <div className="icon-container">
                <FontAwesomeIcon icon={d.icon} className="icon-fa" />
              </div>
              <h4 className="feature-title">{d.title}</h4>
              <p className="feature-text">{d.text}</p>
            </div>
          )) || "..."} {/* Mensaje de carga si no hay datos */}
        </div>
      </div>
    </div>
  );
};
