import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Title from './title';
import { useTranslation } from "react-i18next"; // Para la traducción

export const Services = ({ slides }) => {
  const { t } = useTranslation();
  // Manejo de la carga y la validación de datos
  if (!slides) {
    return <div>Cargando...</div>; // Mensaje de carga
  }

  if (slides.length === 0) {
    return <div>No hay datos disponibles.</div>; // Mensaje en caso de que no haya datos
  }

  return (
    <>

      <div id="serviceCarousel" className="carousel slide carouselservice" data-ride="carousel">
        <Title title={t("servicesTitle")} />
        {/* Indicadores del carrusel */}
        <ol className="carousel-indicators">
          {slides.map((_, index) => (
            <li
              key={index}
              data-target="#serviceCarousel"
              data-slide-to={index}
              className={index === 0 ? "active" : ""}
            ></li>
          ))}
        </ol>
        <div className='container'>
          {/* Contenido del carrusel */}
          <div className="carousel-inner">
            {slides.map((slide, index) => (
              <div className={`item ${index === 0 ? "active" : ""}`} key={index}>
                <h1 className="carousel-title">{slide.title}</h1>
                <div className="row">
                  {slide.items.map((item, itemIndex) => (
                    <div className="col-md-6 text-center" key={itemIndex}>
                      <div className="item-container">
                        <img
                          src={item.image}
                          alt={item.text}
                          className="img-thumbnail" // Clase para las imágenes de 130x130
                        />
                        <p className='carousel-service-text'>{item.text}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Controles del carrusel */}
        <a
          className="left carousel-control"
          href="#serviceCarousel"
          role="button"
          data-slide="prev"
        >
          <div className="carousel-icon">
            <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
          </div>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="right carousel-control"
          href="#serviceCarousel"
          role="button"
          data-slide="next"
        >
          <div className="carousel-icon">
            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
          </div>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </>
  );
};
