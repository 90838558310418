import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';  // Importamos isMobile de react-device-detect

const Modal = ({ isOpen, onClose, url }) => {
  const { t } = useTranslation();  // Usamos useTranslation para obtener el texto traducido

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={() => onClose()}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          {t('Modal.closeButton')} {/* Texto del botón de cerrar traducido */}
        </button>

        {isMobile ? (
          // En dispositivos móviles, mostramos un enlace en lugar del iframe
          <div className="mobile-view">
            <p>{t('Modal.mobileViewText')}</p> {/* Texto para dispositivos móviles */}
            <a href={url} target="_blank" rel="noopener noreferrer">
              {t('Modal.openDocument')} {/* Texto del enlace traducido */}
            </a>
          </div>
        ) : (
          // Para escritorio, mostramos el iframe
          <iframe
            src={url}
            title="Document Preview"
            width="100%"
            height="600px"
            className="modal-iframe"
          />
        )}
      </div>
    </div>
  );
};

export default Modal;
