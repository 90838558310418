import React from "react";
import Title from './title';
import { useTranslation } from "react-i18next"; // Para la traducción

export const About = (props) => {
  const { t } = useTranslation(); // Obtener la función de traducción

  return (
    <div id="about">
      <Title title={t("About.title")} /> {/* Título traducido */}
      <div className="container about-container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src="img/nosotros.webp" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{t("About.subtitle")}</h2> {/* Subtítulo traducido */}
              <p>{t("About.paragraph1")}</p> {/* Primer párrafo */}
              <p>{t("About.paragraph2")}</p> {/* Segundo párrafo */}
            </div>
          </div>
        </div>

        {/* Sección del MapBox (Ubicación y dirección) */}
        <div className="row" style={{ marginTop: '50px' }}>
          <div className="col-xs-12">
            
            <div className="social">
              <ul>
              <h3>{t("About.locationTitle")}</h3> {/* Título de "Ubicación" */}
                <li>
             
                  <a href="https://www.google.com/maps?q=29.0809106,-110.9718183" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-map-marker"></i>
                  </a>
                </li>
                <p>Av. Dr. Ignacio Pesqueira 196B, Hermosillo, Sonora, México</p> {/* Dirección */}
              </ul>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};
