import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';

const resources = {
    en: {
      translation: translationEN,
    },
    es: {
      translation: translationES,
    },
  };

  i18n
  .use(initReactI18next) // paso para usar react-i18next
  .init({
    resources,
    lng: 'en', // Idioma por defecto
    fallbackLng: 'es', // Idioma de respaldo
    interpolation: {
      escapeValue: false, // React ya se encarga de esto
    },
  });

export default i18n;